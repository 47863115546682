import {useEffect} from 'react'

export default async function useDisableScroll(disable) {
  useEffect(() => {
    if (disable) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [disable])
}
