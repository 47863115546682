import Button from '@components/Button'
import ClientSuspense from '@components/ClientSuspense'
import useDisableScroll from '@hooks/useDisableScroll'
import useEffect from '@hooks/useEffect'
import useSessionStorageState from '@hooks/useSessionStorageState'
import useState from '@hooks/useState'
import {useRouter} from 'next/router'
import React from 'react'
import ClickOutside from 'react-click-outside'
import {FaTimes} from 'react-icons/fa'

import {Transition} from 'react-transition-group'

import styles from './styles.module.css'

export default function Popup(props) {
  const {
    onlyClosedByButtons,
    classicCloseButton,
    keepShowingOnSession,
    principalButtonText,
    closeButtonText,
    principalUrlRedirect,
    closeUrlRedirect,
    content,
    id,
  } = props
  const [show, setShow] = useState(false)
  const [exited, setExited] = useState(false)
  const [popup, setPopup] = useSessionStorageState(id)
  const router = useRouter()
  const onClose = url => {
    if (url) {
      if (url.startsWith('/')) {
        router.replace(url)
      } else {
        window.location.href = url
      }
    } else {
      setShow(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 100)

    if (popup && !keepShowingOnSession) {
      setTimeout(() => {
        setShow(false)
      }, 150)
    } else {
      setPopup('true')
    }
  }, [])

  useDisableScroll(show)

  if (exited) return null

  const shouldPreventOnClickOutside = () => {
    if (onlyClosedByButtons) return true
    if (document.getElementById('v3_modal_open') || document.getElementById('v3_modal')) {
      // This is hacky, but couldn't think of a better way to prevent the click outside when there are two modals open
      return true
    }
    return false
  }

  return (
    <ClientSuspense fallback={null}>
      <Transition in={show} timeout={500} appear onExited={() => setExited(true)}>
        {state => (
          <div className={styles[`container_${state}`]}>
            <ClickOutside
              onClickOutside={() => {
                if (!shouldPreventOnClickOutside()) setShow(false)
              }}
            >
              <div
                className={`${styles[`content_${state}`]} relative`}
                id={`popup_modal_${show ? 'open' : 'closed'}`}
              >
                <div className={styles.popupContentWrapper}>
                  {classicCloseButton ? (
                    <div
                      className="absolute h-8 w-8 -right-3 -top-3 primaryBackgroundColor rounded-full text-sm font-semibold text-white shadow-sm flex justify-center items-center cursor-pointer"
                      onClick={() => setShow(false)}
                    >
                      <FaTimes />
                    </div>
                  ) : null}
                  <div
                    className={styles.popupContent}
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                    dangerouslySetInnerHTML={{__html: content}}
                  />
                  {principalButtonText ? (
                    <Button primary onClick={() => onClose(principalUrlRedirect)}>
                      {principalButtonText}
                    </Button>
                  ) : null}
                  {closeButtonText ? (
                    <Button secondary="true" onClick={() => onClose(closeUrlRedirect)}>
                      {closeButtonText}
                    </Button>
                  ) : null}
                </div>
              </div>
            </ClickOutside>
          </div>
        )}
      </Transition>
    </ClientSuspense>
  )
}
